import { Outlet, NavLink, Link } from "react-router-dom";

import myLogo from "../../assets/tradesun_logo.png";

import styles from "./Layout.module.css";

import { useLogin } from "../../authConfig";

import { LoginButton } from "../../components/LoginButton";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <img // Add your logo here
                            src={myLogo}
                            alt="TradeSun Logo"
                            aria-label="TradeSun logo"
                            width="180px" // Adjust the size as needed
                            height="80px"
                            className={styles.myLogo}
                        />
                        <h3 className={styles.headerTitle}>TradeSun Assistant</h3>
                    </Link>
                    <nav>
                        <ul className={styles.headerNavList}>
                            {/*<li>*/}
                            {/*    <NavLink to="/" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>*/}
                            {/*        Chat Mode*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}
                            {/*<li className={styles.headerNavLeftMargin}>*/}
                            {/*    <NavLink to="/qa" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>*/}
                            {/*        QA Mode*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}
                        </ul>
                    </nav>
                    {/*<h4 className={styles.headerRightText}>AI Search</h4>*/}
                    {useLogin && <LoginButton />}
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;